<template>
  <div class="payList_content">
    <div class="bg_img">
      <img @click="dialogVisible = true" class="logout-logo" src="/img/Oval 2@2x.png" alt="">
      <img class="logo_bj" src="/img/Group15@2x.png" alt="">
      <img style="border-radius: 50%;" class="logo_head" :src="userInfo.imagePath ? userInfo.imagePath : headImg" alt="">
      <p class="phone">{{ userInfo.name + '&nbsp;' + verifyPhone(userInfo.mobile) }}</p>
      <el-button round icon="el-icon-arrow-left" @click="$router.push({ path: '/nftmarket', replace:true })">{{ $t('marketPayList.continueShop') }}</el-button>
      <div class="download">
        <div class="appButton">
          <a class="appBtn" href="https://apps.apple.com/cn/app/hunter-king-wallet/id1491881321"></a>
              <a class="appBtn" href="https://play.google.com/store/apps/details?id=com.hunter.wallet"></a>
              <a class="appBtn" href="/android/latest_release.apk"></a>
        </div>
      </div>
    </div>
    <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('marketPayList.all')" name="first">
      <order v-if="Order === '0'" :Order="this.Order"></order>
    </el-tab-pane>
    <el-tab-pane :label="$t('marketPayList.obligation')" name="second"><order v-if="Order === '1'" :Order="this.Order"></order></el-tab-pane>
    <el-tab-pane :label="$t('marketPayList.canceled')" name="third"><order v-if="Order === '2'" :Order="this.Order"></order></el-tab-pane>
    <el-tab-pane :label="$t('marketPayList.havePaid')" name="fourth"><order v-if="Order === '3'" :Order="this.Order"></order></el-tab-pane>
  </el-tabs>
    </div>
    <el-dialog
  :title="$t('headerBox.outLogin')"
  :visible.sync="dialogVisible"
  top="15%"
  width="30%">
  <span>{{ $t('headerBox.outLoginOk') }}</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">{{ $t('headerBox.cancel') }}</el-button>
    <el-button type="primary" @click="okBtn">{{ $t('headerBox.Ok') }}</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import Order from './components/order.vue'
import { verifyPhone } from '@/common/common.js'
import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'marketPayList',
    components: {
      Order
    },
    data() {
      return {
        headImg: localStorage.getItem('headImg'),
        Order: '0',
        activeName: 'first',
        verifyPhone: verifyPhone,
        dialogVisible: false,
      };
    },
    computed: {
      ...mapState(['userInfo'])
    },
    methods: {
      ...mapMutations([ 'remTokenInfo']),
      handleClick(tab, event) {
        if(tab.name === 'first') {
          this.Order = '0'
        }
        if(tab.name === 'second') {
          this.Order = '1'
        }
        if(tab.name === 'third') {
          this.Order = '2'
        }
        if(tab.name === 'fourth') {
          this.Order = '3'
        }
      },
      okBtn() {
      this.remTokenInfo()
      this.dialogVisible = false
      this.$message.success(this.$t('message.escLoginOk'))
      this.$router.push('/nftmarket')
    },
    }
  };
</script>

<style>

</style>