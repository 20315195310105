<template>
  <div class="pay_list_order">
    <!-- 暂无数据 -->
    <div class="noData" v-if="noData === 1">
      <img src="/img/Group44@2x.png" alt="" />
      <div style="margin-bottom: 10px;">{{ $t('order.noData') }}</div>
      <!-- <div>No data</div> -->
    </div>
    <!-- 暂无数据 -->
    <div v-else class="order" v-for="item in List" :key="item.index">
      <div class="order_title">
        <p>
          {{ formatTime(parseInt(item.created) * 1000, 'YYYY-MM-DD hh:mm:ss') }}
        </p>
        <p class="serial">
          {{ $t('order.orderSerial') + '&nbsp;' + item.orderNo
          }}<img
            class="copy"
            src="/img/CombinedShape@2x.png"
            alt=""
            @click="copyBtn(item)"
          />
        </p>
        <p
          :style="
            item.status === 'succeed'
              ? 'color:#52D132'
              : item.status === 'waiting'
              ? 'color:#FFD33A'
              : 'color: red'
          "
        >
          {{
            item.status === 'succeed'
              ? $t('order.havePaid')
              : item.status === 'waiting'
              ? $t('order.obligation')
              : $t('order.canceled')
          }}
        </p>
      </div>
      <el-divider></el-divider>
      <div class="order_content">
        <div class="content_left">
          <img :src="item.nft[0].image" alt="" />
          <div class="info">
            <p>{{ item.nft[0].code }}</p>
            <img v-if="item.nft[0].categoryImage" :src="item.nft[0].categoryImage" alt="">
            <p>
              {{ priceDispose(item.nft[0].price) + '&nbsp;' + item.nft[0].currency }}
            </p>
          </div>
        </div>
        <div class="content_right" v-if="item.status === 'waiting'">
          <div class="countDown">
            <div class="countDownText"
              >{{ $t('order.orderTime') }}</div
            >
            <!-- <count-down
              class="countDownTime"
              v-on:end_callback="countDownE_cb"
              :startTime="parseFloat(item.created) * 1000"
              :endTime="getDataTime(item.created)"
            ></count-down> -->
            <count-down class="countDownTime" v-on:end_callback="countDownE_cb" :startTime="Date.parse(new Date())" :endTime="getDataTime(item.created)" :hourTxt="' : '" :minutesTxt="' : '" :secondsTxt="''"></count-down>
          </div>
          <img src="/img/Group@2x.png" alt="" @click="PayBtn(item)" />
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        :hide-on-single-page="page.total <= 1 ? true : false"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrderList } from '../../../api/api'
import { priceDispose } from '@/common/common.js'
import CountDown from 'vue2-countdown'
export default {
  components: {
    CountDown,
  },
  name: 'order',
  props: ['Order'],
  data() {
    return {
      priceDispose,
      page: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      noData: 0,
      List: [
        //   {
        //   created: '2022-06-01 18:00',
        //   orderNo:'111111111111111',
        //   status: 'waiting',
        //   nft: [
        //     {
        //       code: 120,
        //       currency: 'HD',
        //       image: '',
        //       price: 1200,
        //     }
        //   ]
        // }
      ],
    }
  },
  created() {
    this.getOrderList()
    // let a = this.formatTime(1654659182 * 1000 + 603000, 'YYYY-MM-DD hh:mm:ss')
    // console.log(a)
  },
  methods: {
    getDataTime(time) {
      let data = parseInt(time) * 1000 + 605000
      return data
    },
    countDownE_cb() {
        return this.getOrderList()
    },
    async getOrderList() {
      const res = await getOrderList(
        this.Order,
        this.page.page,
        this.page.pageSize
      )
      this.page.total = res.data.total
      this.List = res.data.result
      console.log(this.List)
      if(this.List.length === 0) {
          this.noData = 1
        } else {
          this.noData = 0
        }
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getOrderList()
    },
    handleCurrentChange(val) {
      this.page.page = val
      this.getOrderList()
      window,scrollTo(0,0);
    },
    PayBtn(item) {
      this.$router.push(`/nftmarket/marketPay?orderNo=${item.orderNo}`)
      window.scrollTo(99999, 0)
    },
    copyBtn(item) {
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item.orderNo
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success(this.$t('message.copyOk'))
      //移除input标签
      document.body.removeChild(input)
    },
  },
}
</script>

<style lang="less" scoped>
.pay_list_order {
  margin-top: 5px;
  padding-bottom: 57px;
  .order {
    box-sizing: border-box;
    height: 277px;
    background-color: #222222;
    margin-bottom: 15px;
    padding: 20px 40px 22px 27px;
    .order_title {
      color: #939393;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      .serial {
        // margin-left: -100px;
        .copy {
          width: 14px;
          height: 14px;
          margin-left: 17px;
          cursor: pointer;
        }
      }
    }
    .el-divider--horizontal {
      background-color: #979797;
      margin: 19px 0;
    }
    .order_content {
      display: flex;
      justify-content: space-between;
      .content_left {
        display: flex;
        img {
          width: 168px;
          height: 168px;
          margin-right: 8px;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          img {
            margin-top: 7px;
            margin-bottom: 39px;
            width: 166px;
            height: 40px;
          }
          p {
            height: 42px;
            color: #fff;
            font-size: 30px;
          }
        }
      }
      .content_right {
        padding-top: 5px;
        position: relative;
        // width: 281px;
        // height: 147px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        img {
          width: 281px;
          cursor: pointer;
          position: absolute;
          bottom: -20px;
          right: -35px;
        }
        .countDown {
          // position: absolute;
          // left: 35px;
          // top: 0;
          // right: 0;
          // width: 225px;
          // display: flex;
          // align-items: center;
          font-size: 20px;
          color: #ffffff;
          // margin-bottom: 5px;
          font-size: 18px;
          .countDownText {
            margin-bottom: 5px;
          }
          .countDownTime {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
